import React, { ReactNode } from "react";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
// Import your copy icon component here. For demonstration, I'll create a simple placeholder.
const CopyIcon = () => <span>📋</span>;

type CLipboardLabelProps = {
  children: ReactNode;
};

const CLipboardLabel: React.FC<CLipboardLabelProps> = ({ children }) => {
  const copyTextToClipboard = async () => {
    try {
      const textToCopy = React?.Children?.map(children, (child) =>
        child && typeof child === "string" ? child : ""
      )?.join("");
      if (!textToCopy) return;
      await navigator.clipboard.writeText(textToCopy ?? "");
      showSuccessMessage("Text copied to clipboard");
    } catch (err) {
      showErrorMessage("Failed to copy: ");
    }
  };

  return (
    <span
      onClick={copyTextToClipboard}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      {children}
      <CopyIcon />
    </span>
  );
};

export default CLipboardLabel;

import React, { useState } from "react";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import {
  AfriexActions,
  AfriexAdmin,
  AfriexPermissions,
} from "../../../../types";
import { Button } from "../../../../components";
import { formatDate } from "../../../../utils/date";
import DeactivateReasonModal from "../../../../components/CustomModal/ActivationModal";
import { PermissionsProvider } from "../../../../components/common/PermissionsProvider";
import UpdateRoleModal from "../../../../components/CustomModal/UpdateRoleModal";
import {
  AfriexOffboardingRoles,
  AfriexRoles,
} from "../../../../types/AfriexRoles";
import { doNothing } from "../../../../constants/formatters";
import useCloseOnOutsideClick from "../../../../hooks/useCloseOnOutsideClick";
import useUserDetails from "../../../User/Details/useUserDetails";

const AdminTable = ({ items }: { items: AfriexAdmin[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Personnel Name
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Email
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Role
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Last Login
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item) => {
          return <AdminTableRow item={item} key={item.userId} />;
        })}
      </tbody>
    </table>
  );
};

const AdminTableRow = ({ item }: { item: AfriexAdmin }) => {
  const textClassName = "text-sm text-gray-800";
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);
  const loggedInRole = localStorage.getItem("admin-role");

  const { user } = useUserDetails(item.userId);
  const toggleRoleModal = () => setIsRoleModalOpen(!isRoleModalOpen);

  const hasOffboardingAccess = Object.values(AfriexOffboardingRoles).includes(
    loggedInRole as AfriexOffboardingRoles
  );

  const isEngineeringManager = item.role === AfriexRoles.ENGINEERING_MANAGER;

  const openActions = () => setShowOptions((prev) => !prev);
  const closeActions = () => setShowOptions(false);

  const containerRef = useCloseOnOutsideClick<HTMLDivElement>(closeActions);

  return (
    <React.Fragment>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td
          className={`pl-6 py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item.fullName}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.email}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.role ?? "-"}
        </td>

        {/* Last Login */}
        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate(item?.updatedAt ?? "")}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left relative ${textClassName}`}
        >
          <div
            ref={containerRef}
            onClick={
              hasOffboardingAccess && !isEngineeringManager
                ? openActions
                : doNothing
            }
            className={`relative w-5 h-5 flex justify-center items-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 cursor-pointer`}
          >
            <DotsHorizontalIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />

            {showOptions && !user.isDeactivated && (
              <div className="absolute flex items-end flex-col top-4 right-0 bg-white border py-3 pr-4 gap-3 z-10 rounded w-36">
                <PermissionsProvider
                  permission={AfriexPermissions.SETTINGS_PAGE}
                  action={AfriexActions.CLICK_SETTINGS_UPDATE_ROLE}
                >
                  <Button
                    variant="unStyled"
                    className="hover:text-cyan-700 no-margin no-padding"
                    onClick={toggleRoleModal}
                  >
                    Update Role
                  </Button>
                </PermissionsProvider>

                <PermissionsProvider
                  permission={AfriexPermissions.SETTINGS_PAGE}
                  action={AfriexActions.CLICK_SETTINGS_DEACTIVATE_ADMIN}
                >
                  <Button
                    variant="unStyled"
                    className="hover:text-red-700 text-red-500"
                    onClick={() => setOpenDeactivateModal(true)}
                  >
                    Deactivate
                  </Button>
                </PermissionsProvider>
              </div>
            )}
          </div>
        </td>

        <DeactivateReasonModal
          isOpen={openDeactivateModal}
          onClose={() => setOpenDeactivateModal(false)}
          user={user}
          type="admin"
        />

        <UpdateRoleModal
          isOpen={isRoleModalOpen}
          onClose={() => setIsRoleModalOpen(false)}
          user={user}
          defaultRole={item.role}
        />
      </tr>
    </React.Fragment>
  );
};

export default AdminTable;

import { AfriexOTCTrade } from "../../../types";
import { OTCTradeAction } from "./types";
import { OTCTradeActionType } from "./types";

const updateTradeReducer = (state: AfriexOTCTrade, action: OTCTradeAction) => {
  switch (action.type) {
    case OTCTradeActionType.UPDATE_OTC_TRADE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default updateTradeReducer;

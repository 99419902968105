import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "..";

const GenerateOnePageButton = ({
  fileName,
  label,
}: {
  fileName: string;
  label: string;
}) => {
  const generatePDF = async () => {
    const html2pdf = await require("html2pdf.js");
    const element = document.getElementById("receipt-parent");

    if (!element) {
      console.error("Element with id 'receipt-parent' not found");
      return;
    }
    element.classList.remove("border-receipt-line", "border");

    const curTime = new Date().getTime();
    const name = `${curTime}_${fileName ?? ""}_afriex.pdf`;

    const pdfOptions = {
      filename: name,
      image: { type: "png" },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [element?.offsetWidth, element?.offsetHeight + 5],
        hotfixes: ["px_scaling"],
        compress: true,
      },
    };
    html2pdf().from(element).set(pdfOptions).save();

    setTimeout(() => {
      element.classList.add("border-receipt-line");
      element.classList.add("border");
    }, 100);
  };

  return (
    <Button
      onClick={generatePDF}
      colorScheme="cyan"
      variant="outline"
      leftIcon={<DownloadIcon className="h-5 w-5" />}
      className="bg-indigo-200"
    >
      {label}
    </Button>
  );
};

export default GenerateOnePageButton;

import moment from "moment";
import momentTz from "moment-timezone";

export function DDMonYYYY(dateString: string, showTime = false): string {
  const time = momentTz(dateString);
  const localTimeZone = momentTz.tz.guess(true);
  const date = time.tz(localTimeZone);
  const formatDate = momentTz(date).format(
    `DD MMM YYYY${showTime ? ", h:mm A" : ""}`
  );
  return formatDate;
}

export function dateTimeInTimeZone(dateString: string): string {
  const time = momentTz(dateString);
  const localTimeZone = momentTz.tz.guess(true);
  const date = time.tz(localTimeZone);
  const formatDate = momentTz(date).format("DD MMMM YYYY, h:mm:ss A z");
  return formatDate;
}

export function isTwentyFourHoursAhead(date: Date): boolean {
  const givenTime = new Date(date).getTime();
  const currentTime = Date.now();
  const diffInHours = (currentTime - givenTime) / (1000 * 60 * 60); // Convert milliseconds to hours

  return diffInHours > 24;
}

export const formatDate = (date: string): string =>
  moment.utc(date).format("D MMM, YYYY");

export const formatTime = (date: string): string =>
  moment(date).format("hh:mm A");

export const formatTimeDifference = (
  timestamp1: number,
  timestamp2: number
) => {
  // Calculate difference in milliseconds directly
  const difference = Math.abs(timestamp1 - timestamp2);

  // Calculate days, hours, minutes, seconds, and milliseconds
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  const milliseconds = difference % 1000;

  // Build the human-readable string
  let result = "";
  if (days > 0) result += days + "d ";
  if (hours > 0) result += hours + "h ";
  if (minutes > 0) result += minutes + "m ";
  if (seconds > 0) result += seconds + "s ";
  if (milliseconds > 0) result += milliseconds + "ms";

  return result.trim();
};

export const getLast7DaysRange = () => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  return {
    fromDate: sevenDaysAgo.toISOString().split("T")[0], // YYYY-MM-DD format
    toDate: today.toISOString().split("T")[0], // YYYY-MM-DD format
  };
};

export const formatDateForFileName = () => {
  const date = new Date();
  return date
    .toLocaleDateString("en-US", {
      month: "short", // Jan
      day: "numeric", // 2
      year: "numeric", // 2024
    })
    .replace(/,/g, "")
    .replace(/ /g, "_"); // Replace spaces with underscores
};

import { useCallback, useEffect, useState } from "react";
import { transactionService } from "../../../services/transactionService";
import {
  AfriexQueryParams,
  AfriexTransaction,
  AfriexTransactionStatus,
} from "../../../types";
import { TransactionListProps } from "./types";
import { attachAdminUser } from "../../../utils/dashboard";
import { useLocation } from "react-router-dom";
import useFilters from "../../../hooks/useFilters";
import { omit } from "lodash";

const useTransactionList = (): TransactionListProps => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status") as AfriexTransactionStatus;

  const [transactionList, setTransactionList] = useState<AfriexTransaction[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);
  const [transactionTotal, setTransactionTotal] = useState<number>(0);
  const [showPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexQueryParams>({});
  const [filtersToApply, setFiltersToApply] = useState<AfriexQueryParams>({});
  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllTransactions = useCallback(
    async (params?: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const transactionData = await transactionService.getTransactionList({
          ...params,
          ...currentFilters,
        });

        const filtered = await Promise.all(
          transactionData?.data &&
            transactionData?.data.map(async (transaction) => {
              const at = await attachAdminUser(transaction);
              return {
                ...transaction,
                type: at.type,
                adminUser: at.adminUser,
              };
            })
        );
        setTransactionList(filtered);
        setTransactionTotal(transactionData?.total ?? 0);
      } catch (error) {
        setError(error as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  useEffect(() => {
    fetchAllTransactions({});
  }, [fetchAllTransactions]);

  useEffect(() => {
    if (status) {
      setCurrentFilters((prev) => {
        const updatedFilters = { ...prev, status };
        return updatedFilters;
      });
    } else {
      const updatedFilters = omit(currentFilters, ["status"]);
      setCurrentFilters(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleApplyFilters = useCallback(() => {
    if (status) {
      filtersToApply.status = status; // Ensure status is included
    }
    applyFilters(filtersToApply); // Apply the filters
    setIsPageReset((prev) => !prev); // Reset pagination to the first page
  }, [applyFilters, filtersToApply, status]);

  // Handle change for user manipulation
  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  // Handle dates for applied filters
  const selectAppliedFilters = useCallback(
    (name: string, value: string | number | Date | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        setFilterCount(Object.keys(updatedFilters).length);
        return updatedFilters;
      });
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({});
    setFiltersToApply({});
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
    if (status) setCurrentFilters({ status });
  };

  return {
    error,
    fetchTransactionList: fetchAllTransactions,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: transactionTotal,
    },
    showPagination,
    transactionList,
    filterCount,
    isPageReset,
    currentFilters,
    filtersToApply,
    clearFilters: cleanFilters,
    selectAppliedFilters,
    handleApplyFilters,
    selectCurrentFilters,
  };
};

export default useTransactionList;

import { AdminTransactionTypes, TransactionTypes } from "../types/Transaction";
import * as formatDate from "./date";
import { getNigeriaBankName } from "./bankUtil";
import { SupportedAssets } from "../types/AssetTypes";
import { AfriexTransaction, RatesMap } from "../types";
import { capitalizeWords, formatValues } from "./dashboard";
import { capitalize } from "lodash";

export const showCurrencyRate = (transaction: AfriexTransaction): string => {
  const ratesObject = transaction?.rates as RatesMap;
  const sourceAsset = transaction.sourceAsset as SupportedAssets;
  const destinationAsset = transaction.destinationAsset as SupportedAssets;
  const nestedRate =
    ratesObject?.[sourceAsset as SupportedAssets]?.[
      destinationAsset as SupportedAssets
    ] ?? 0;
  const flattenedRate = ratesObject?.[destinationAsset as SupportedAssets] ?? 0;
  const exchangeRate =
    flattenedRate?.constructor === Object ? nestedRate : flattenedRate;
  return `1 ${transaction.sourceAsset} = ${formatValues(Number(exchangeRate), "rate", 6)} ${
    transaction?.destinationAsset as string
  }`;
};

interface DisplayField {
  [key: string]: string | number | any;
}

export function getFieldsToDisplay(
  transaction: AfriexTransaction
): DisplayField[] {
  const deviceInfoFields = [
    { "Customer Device Id": transaction?.deviceInfo?.deviceId },
    { "Customer Device Type": transaction?.deviceInfo?.deviceType },
    {
      "Customer Device Name": transaction?.deviceInfo?.deviceName?.replace(
        /[{}]/g,
        ""
      ),
    },
  ];

  const typeCheck =
    Object.values(AdminTransactionTypes).find(
      (key) =>
        key ===
        AdminTransactionTypes[
          transaction.type as unknown as keyof typeof AdminTransactionTypes
        ]
    ) || transaction.type;

  switch (typeCheck) {
    case TransactionTypes.WITHDRAW:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Processor: transaction.processor },
        {
          "Fulfillment Amount": formatValues(
            Number(transaction.destinationAmount),
            "money"
          ),
        },
        { "Fulfillment Asset": transaction.destinationAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        {
          Amount: formatValues(Number(transaction.sourceAmount), "money"),
        },
        { Currency: transaction.sourceAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { "Initiated By": transaction?.adminUser?.name },
        { Reason: capitalize(transaction.reason?.replace(/_/g, " ")) },
        { "Processor Reference": transaction.processorTransactionId },
        { "Internal Reference": transaction.internalTransactionId },
        {
          "Session ID": transaction?.processorResponseMeta?.sessionId,
        },
        {
          "Bank name":
            transaction.destinationAccountDetails?.bankName ||
            getNigeriaBankName(transaction.destinationAccountDetails?.bankCode),
        },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        {
          "Bank account name":
            transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Name": transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Tier Id": transaction?.tierId ?? "None" },
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        {
          "Status Description":
            transaction?.processorResponseMeta?.statusMessage ||
            transaction?.processorResponseMeta?.responseMessage ||
            transaction?.processorResponseMeta?.transactionStatusDescription,
        },
        ...deviceInfoFields,
      ];
    case TransactionTypes.DEPOSIT:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        {
          "Ledger Transaction IDs": transaction.ledgerTransactionIds,
        },
        { "Transaction IP Address": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          Amount: formatValues(Number(transaction.destinationAmount), "money"),
        },
        { Currency: transaction.destinationAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        {
          "Fulfillment Amount": formatValues(
            Number(transaction.sourceAmount),
            "money"
          ),
        },
        { "Fulfillment Asset": transaction.sourceAsset },
        { "Customer ID": transaction.sourceUserId },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Processor: transaction.processor },

        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalize(
            transaction?.reason?.toString().replace(/_/g, " ")
          ),
        },
        { Fee: transaction.fee },
        { "Processor Reference": transaction.processorTransactionId },
        {
          "Stripe Payment Intent Id":
            transaction.processorResponseMeta?.paymentIntentId,
        },
        { "Internal Reference": transaction.internalTransactionId },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    case TransactionTypes.REVERSAL:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Description: transaction.type },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          "Fulfillment Amount": formatValues(
            Number(transaction.destinationAmount),
            "money"
          ),
        },
        { "Fulfillment Asset": transaction.destinationAsset },
        {
          Amount: formatValues(Number(transaction.sourceAmount), "money"),
        },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { "Processor Reference": transaction.processorTransactionId },
        { "Internal Reference": transaction.internalTransactionId },
        { "Session ID": transaction?.processorResponseMeta?.sessionId },
        { "Bank name": transaction.destinationAccountDetails?.bankName },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        {
          "Bank account name":
            transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Name": transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Customer ID": transaction.destinationUserId },
        { Status: transaction.status },
      ];
    case TransactionTypes.REFERRAL:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          Amount: formatValues(Number(transaction.sourceAmount), "money"),
        },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    case TransactionTypes.TRANSFER:
      return [
        { "Type of Transaction": `User to User` },
        {
          "Transaction ID": transaction.id,
        },
        { "Ledger Transaction IDs": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        { Description: transaction.processorResponseMeta?.description },
        { "Sender Username": transaction.sourceAccountDetails?.name },
        { "Receiver Username": transaction.destinationAccountDetails?.name },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Sender: transaction.sourceUserId },
        { Receiver: transaction.destinationUserId },
        {
          Amount: formatValues(Number(transaction.sourceAmount), "money"),
        },
        { Currency: transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        {
          "Recipient Amount": formatValues(
            Number(transaction.destinationAmount),
            "money"
          ),
        },
        { "Recipient Currency": transaction.destinationAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { Status: "Successful" },
        ...deviceInfoFields,
      ];
    case TransactionTypes.SWAP:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { Description: transaction.type },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction ID": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        { "Fulfillment Asset": transaction.destinationAsset },
        {
          "Fulfillment Amount": formatValues(
            Number(transaction.destinationAmount),
            "money"
          ),
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Fee: transaction.fee },
        {
          Amount: formatValues(Number(transaction.sourceAmount), "money"),
        },
        { Currency: transaction.sourceAsset },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { "Customer Id": transaction.sourceUserId },
        { "Initiated By": transaction.initiatedBy },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    default:
      return [
        {
          "Type of Transaction": capitalizeWords(
            transaction.type?.replace(/_/g, " ")
          ),
        },
        { "Transaction ID": transaction.id },
        { "Ledger Transaction ID": transaction.ledgerTransactionIds },
        { "Transaction IP Address": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Updated Date": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Processor: transaction.processor },

        {
          "Fulfillment Amount": formatValues(
            Number(transaction.sourceAmount),
            "money"
          ),
        },
        { "Fulfillment Asset": transaction.sourceAsset },
        { "Currency Rate": showCurrencyRate(transaction) },
        {
          Amount: formatValues(Number(transaction.destinationAmount), "money"),
        },
        { Currency: transaction.destinationAsset },
        { Channel: capitalizeWords(transaction.channel?.replace(/_/g, " ")) },
        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalize(
            transaction?.reason?.toString().replace(/_/g, " ")
          ),
        },
        { Fee: transaction.fee },
        { "Processor Reference": transaction.processorTransactionId },
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
  }
}

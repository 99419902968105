import { DashCardConfig } from "../config/menuConfig";
import { userService } from "../services/userService";
import { AfriexTransaction, TransactionTypes } from "../types";

export const formatValues = (
  value: number,
  type: DashCardConfig["valueType"],
  fraction?: number, // Control decimal places
  showCurrencySymbol = false // Control currency symbol display
) => {
  let formattedValue: string;
  switch (type) {
    case "money":
      formattedValue = new Intl.NumberFormat("en-US", {
        ...(showCurrencySymbol && {
          currencyDisplay: "symbol",
          style: "currency",
          currency: "USD",
        }),
        maximumFractionDigits: fraction !== undefined ? fraction : 2,
        minimumFractionDigits: fraction !== undefined ? fraction : 2,
      }).format(value);
      break;

    case "rate":
      formattedValue = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: fraction !== undefined ? fraction : 2,
        minimumFractionDigits: Number.isInteger(value)
          ? 0
          : fraction !== undefined
            ? fraction
            : 2,
      }).format(value);
      break;

    default:
      formattedValue = "0"; // Fallback for unexpected types
  }

  // Trim trailing zeros if the value is a decimal
  if (formattedValue.includes(".")) {
    formattedValue = formattedValue.replace(/\.?0+$/, "");
  }

  return formattedValue;
};

export function capitalizeWords(str: string) {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const attachAdminUser = async (data: AfriexTransaction) => {
  let { type } = data;
  let adminUser: { name: string; email: string } | null = null;

  if (
    data.initiatedBy &&
    [
      TransactionTypes.DEPOSIT,
      TransactionTypes.WITHDRAW,
      TransactionTypes.WELCOME_BONUS,
    ].includes(data.type)
  ) {
    type = `ADMIN_${data.type}` as TransactionTypes;
    try {
      const getAdminUser = await userService.getUser(data.initiatedBy);
      adminUser = {
        name: getAdminUser.name.fullName,
        email: getAdminUser.currentEmail,
      };
    } catch (error) {
      console.error("Error fetching admin user:", error);
    }
  }

  return {
    type,
    adminUser: adminUser || null,
  };
};

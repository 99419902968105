import { Header } from "../../../components";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { useNavigate, useParams } from "react-router-dom";
import { AfriexTransaction } from "../../../types";
import { useCallback, useEffect, useState } from "react";
import { transactionService } from "../../../services/transactionService";
import { attachAdminUser } from "../../../utils/dashboard";
import Spinner from "../../../assets/svg/Spinner";
import TransactionReceipt from "../Receipt";

function TransactionReceiptPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [txnLoading, setTxnLoading] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState<AfriexTransaction>(
    {} as AfriexTransaction
  );

  const fetchTransactionDetails = useCallback(async () => {
    try {
      setTxnLoading(true);
      const currentTransaction = await transactionService.getTransaction({
        id: params.transactionId as string,
      });
      const filtered = await attachAdminUser(currentTransaction);
      setTransactionDetail({ ...currentTransaction, ...filtered });
      setTxnLoading(false);
    } catch (err) {
      setTxnLoading(false);
    }
  }, [params.transactionId]);

  useEffect(() => {
    fetchTransactionDetails();
  }, [fetchTransactionDetails]);

  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header
        title="Transaction"
        icon={
          <ArrowNarrowLeftIcon
            className="font-light w-5 h-5 text-black hover:text-indigo-500 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
      />

      <div className="flex justify-center items-center flex-col">
        {txnLoading ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <TransactionReceipt data={transactionDetail} />
        )}
      </div>
    </div>
  );
}

export default TransactionReceiptPage;

import React, { useState } from "react";
import { AfriexRateInfo, AfriexTier } from "../../../types";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";
import { MinusIcon } from "@heroicons/react/solid";
import { Button, Link } from "../../../components";
import * as formatDate from "../../../utils/date";
import TierSummary from "./TierSummary";
import { formatValues } from "../../../utils/dashboard";
type ExtraRowArg = AfriexTier;

const PromoTiersTable = ({
  items,
  rateInfo,
}: {
  items: AfriexTier[];
  rateInfo: AfriexRateInfo;
  extraRow?: (user?: ExtraRowArg) => React.ReactNode;
}) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Discount
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <PromoTiersTRow rateInfo={rateInfo} item={item} key={index} />;
        })}
      </tbody>
    </table>
  );
};

const PromoTiersTRow = ({
  item,
  rateInfo,
}: {
  item: AfriexTier;
  rateInfo: AfriexRateInfo;
}) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <React.Fragment>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td className="pr-6 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item?.name}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatValues((item?.discount ?? 0) * 100, "rate", 6)}%
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.isActive ? "ACTIVE" : "INACTIVE"}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(String(item.createdAt || item.updatedAt))}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Link
            to={`/rates/tiers/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500">
              <ChevronRightIcon className=" w-5 h-5" />
            </div>
          </Link>
        </td>
      </tr>

      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <TierSummary item={item} rateInfo={rateInfo} />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default PromoTiersTable;

import { capitalizeWords } from "../utils/dashboard";
import { SupportedCurrencies } from "./SupportedCurrencies";
import { TransactionTypes } from "./Transaction";

export enum FeePaymentMethod {
  REGULATED_CARD = "REGULATED_CARD",
  UNREGULATED_CARD = "UNREGULATED_CARD",
  ACH_BANK = "ACH_BANK",
  BANK = "BANK",
  WALLET = "WALLET",
  VIRTUAL_BANK_ACCOUNT = "VIRTUAL_BANK_ACCOUNT",
}

export type TransactionFee = {
  id?: string; // is draft when undefined
  paymentMethod?: FeePaymentMethod;
  baseValue?: number;
  percentValue?: number;
  maxFeeValue?: number;
  limitType?: "above" | "below";
  limit?: number;
  currencyPair?: string;
  type: "override" | "default";
  transactionType?: TransactionTypes;
};

export const currencyPairOptions = [
  { key: "Any", value: "*" },
  ...Object.values(SupportedCurrencies).map((currency) => ({
    key: currency,
    value: currency,
  })),
];

export const paymentMethodOptions = [
  { key: "Any", value: "any" },
  ...Object.values(FeePaymentMethod).map((method) => ({
    key: capitalizeWords(method.replace(/_/g, " ")),
    value: method,
  })),
];

export const limitTypeOptions = [
  { key: "None", value: "none" },
  { key: "Above", value: "above" },
  { key: "Below", value: "below" },
];

import { chunk } from "lodash";
import Spinner from "../../../assets/svg/Spinner";
import {
  AfriexPerson,
  AfriexTransaction,
  SupportedAssets,
} from "../../../types";
import StatementTable from "./StatementTable";
import StatementHeader from "./StatementHeader";

const AccountStatement = ({
  user,
  balances,
  transactions,
  dates,
  loading,
}: {
  user: AfriexPerson;
  balances: Partial<Record<SupportedAssets, number>>;
  dates: Date[];
  transactions: AfriexTransaction[];
  loading: boolean;
}) => {
  const transactionChunks = chunk(transactions, 13);
  return (
    <>
      {transactionChunks?.length > 0 ? (
        <div id="statement-parent" className="flex flex-col gap-3">
          {transactionChunks.map((chunkBody, index) => (
            <div
              key={index}
              className={`statement-content flex w-680 flex-col border border-receipt-line rounded-md bg-white p-6`}
            >
              <StatementHeader user={user} balances={balances} dates={dates} />

              {loading ? (
                <div className="w-full flex justify-center mt-10">
                  <Spinner size={8} className="text-cyan-600" />
                </div>
              ) : (
                <div className="statement-transactions mt-4">
                  <StatementTable transactions={chunkBody} />
                </div>
              )}

              <p className="text-xs text-right mt-4">
                Page{" "}
                <span className="text-gray-800 font-bold">{index + 1}</span>
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`flex w-680 flex-col border border-receipt-line rounded-md bg-white p-6`}
        >
          <StatementHeader user={user} balances={balances} dates={dates} />
          <p className="flex font-sm gap-2 justify-center text-gray-500 p-2 rounded-md pt-6">
            No transactions today
          </p>
        </div>
      )}
    </>
  );
};

export default AccountStatement;

import BigNumber from "bignumber.js";
import { AfriexOTCRate } from "../types";
import { cleanNumber } from "./rates/cleanNumber";
import { showErrorMessage } from "./showErrorMessage";

interface RatesResult {
  changedRates: AfriexOTCRate[];
  newTradeRate: number;
}

// Direct Pair -> USD/NGN (newRateValue)
// And all other pairs who's toSymbol equals toCurrency (use the ratio to calculate this)
// And all other pairs inverse

export const computeNewOTCRates = (
  fromAmount: number,
  toAmount: number,
  fromCurrency: string,
  toCurrency: string,
  initialOTCRates: AfriexOTCRate[]
): RatesResult => {
  if (!Array.isArray(initialOTCRates) || initialOTCRates.length === 0) {
    showErrorMessage("No initial OTC rates received.");
    return { changedRates: [], newTradeRate: 0 };
  }

  // Validate inputs
  if (!fromAmount || !toAmount) {
    return { changedRates: [], newTradeRate: 0 };
  }

  // Calculate new trade rate
  const newTradeRate = new BigNumber(toAmount || 0)
    .dividedBy(fromAmount || 1)
    .toNumber();

  // Validate trade rate
  if (isNaN(newTradeRate)) {
    return { changedRates: [], newTradeRate: 0 };
  }

  // Find the original rate for this currency pair
  const originalRate = initialOTCRates?.find(
    (rate) => rate.fromSymbol === fromCurrency && rate.toSymbol === toCurrency
  );

  if (!originalRate) {
    console.warn("Original rate not found for pair");
    return { changedRates: [], newTradeRate: 0 };
  }

  // Calculate ratio
  const ratio = new BigNumber(newTradeRate)
    .dividedBy(originalRate.value)
    .toNumber();

  // Calculate new rates for all pairs
  const changedRates = initialOTCRates
    .map((rate) => {
      let newValue = rate.value;

      // Same currency pairs (e.g., NGN/NGN)
      if (rate.fromSymbol === rate.toSymbol && rate.toSymbol === toCurrency) {
        return {
          fromSymbol: rate.fromSymbol,
          toSymbol: rate.toSymbol,
          value: Number(newValue),
        };
      }
      // Direct pair (USD/NGN)
      else if (
        rate.fromSymbol === fromCurrency &&
        rate.toSymbol === toCurrency
      ) {
        newValue = cleanNumber(newTradeRate.toString()).toString();
      }
      // Other pairs with toCurrency as NGN (GBP/NGN, EUR/NGN etc)
      else if (rate.toSymbol === toCurrency) {
        newValue = cleanNumber(
          new BigNumber(rate.value).multipliedBy(ratio).toString()
        ).toString();
      }

      if (!new BigNumber(newValue).isFinite()) {
        newValue = "0";
      }

      return {
        fromSymbol: rate.fromSymbol,
        toSymbol: rate.toSymbol,
        value: Number(newValue),
      };
    })
    .filter((newRate) => {
      const originalRate = initialOTCRates.find(
        (r) =>
          r.fromSymbol === newRate.fromSymbol && r.toSymbol === newRate.toSymbol
      );

      if (!originalRate) return false;
      const hasValueChanged = !new BigNumber(newRate.value).isEqualTo(
        originalRate.value
      );

      return (
        hasValueChanged ||
        (toCurrency === originalRate.toSymbol &&
          toCurrency === originalRate.fromSymbol)
      );
    });

  return { changedRates, newTradeRate };
};

import { Table, Th, TBody, Tr, Td, Thead } from "../common/Table";
import TableSkeleton from "./Skeleton";
import { DDMonYYYY as formatDate } from "../../utils/date";
import { IBulkUploadPayment } from "../../types/BulkPayment";
import { toast } from "react-toastify";
import { fileService } from "../../services/fileService";

type TransactionsTableProp = {
  data: IBulkUploadPayment[];
  loading: boolean;
};

export default function BulkUploadPaymentTable({
  data,
  loading,
}: TransactionsTableProp): JSX.Element {
  const getUrl = async (url: string, userId: string): Promise<any> => {
    if (!url) return toast.error("Url data not found!");

    const urlArray = url.split("/");
    const name = urlArray[3];

    const s3url = await fileService.fetchUploadedFile(
      name,
      "aws.s3Bucket.bulkUpload",
      userId
    );
    window.open(s3url, "_parent");
  };
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>Successful</Th>
          <Th>Failed</Th>
          <Th>Type</Th>
          <Th>Comment</Th>
          <Th>Created By</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <TBody>
        {loading ? (
          <TableSkeleton />
        ) : (
          data.map((bulkUploadPayment: IBulkUploadPayment) => (
            <Tr
              key={bulkUploadPayment._id}
              className="hover:bg-indigo-200 transition-background  text-sm transition-colors transition-opacity ease-in-out duration-300"
            >
              <Td>{formatDate(bulkUploadPayment?.createdAt)}</Td>

              <Td className="text-successful-500">
                {bulkUploadPayment?.successCount?.toLocaleString()}
              </Td>
              <Td className="text-failed-500">
                {bulkUploadPayment?.failedCount?.toLocaleString()}
              </Td>
              <Td className="text-500">
                {bulkUploadPayment?.type?.toUpperCase()}
              </Td>
              <Td className="text-500">
                {bulkUploadPayment?.comment ?? "No Inputted Message"}
              </Td>
              <Td>{bulkUploadPayment?.user}</Td>
              <Td>
                <button
                  onClick={() =>
                    getUrl(bulkUploadPayment.url, bulkUploadPayment.user)
                  }
                >
                  View sheet
                </button>
              </Td>
            </Tr>
          ))
        )}
      </TBody>
    </Table>
  );
}

import { useEffect, useReducer, useState } from "react";
import { AfriexAmount, AfriexOTCRate, AfriexOTCTrade } from "../../../types";
import { SupportedCurrencies } from "../../../types";
import updateTradeReducer from "./updateTradeReducer";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { ratesService } from "../../../services/ratesService";
import { computeNewOTCRates } from "../../../utils/otc";
import { OTCTradeActionType } from "./types";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { omit } from "lodash";

const useOTCTradeUpdate = (
  onClose: VoidFunction,
  initialData: Partial<AfriexOTCTrade> = {
    fromSymbol: SupportedCurrencies.USD,
    toSymbol: SupportedCurrencies.NGN,
  }
) => {
  const [otcTrade, dispatch] = useReducer(
    updateTradeReducer,
    initialData as AfriexOTCTrade
  );
  const [isLoading, setIsLoading] = useState(false);
  const [initialOTCRates, setInitialOTCRates] = useState<AfriexOTCRate[]>([]);
  const [newOTCRates, setNewOTCRates] = useState<AfriexOTCRate[]>([]);
  const [newTradeRate, setNewTradeRate] = useState<number>(0);

  useEffect(() => {
    ratesService.getOTCRatesList().then(setInitialOTCRates);
  }, []); // Empty dependency array for initial fetch only

  const updateTradeValues = (
    value: AfriexAmount | Date | undefined,
    type: "fromAmount" | "toAmount" | "date"
  ) => {
    const amounts = {
      fromAmount:
        type === "fromAmount"
          ? (value as AfriexAmount).amount
          : otcTrade.fromAmount,
      fromSymbol:
        type === "fromAmount"
          ? (value as AfriexAmount).currency
          : otcTrade.fromSymbol,
      toAmount:
        type === "toAmount"
          ? (value as AfriexAmount).amount
          : otcTrade.toAmount,
      toSymbol:
        type === "toAmount"
          ? (value as AfriexAmount).currency
          : otcTrade.toSymbol,
      timestamp:
        type === "date" ? (value as Date) || otcTrade.timestamp : new Date(),
    };

    if (amounts.fromAmount && amounts.toAmount && initialOTCRates.length > 0) {
      const { changedRates, newTradeRate } = computeNewOTCRates(
        amounts.fromAmount,
        amounts.toAmount,
        amounts.fromSymbol,
        amounts.toSymbol,
        initialOTCRates
      );

      setNewOTCRates(changedRates);
      setNewTradeRate(newTradeRate);

      dispatch({
        type: OTCTradeActionType.UPDATE_OTC_TRADE,
        payload: {
          ...amounts,
          rate: newTradeRate,
        },
      });
    } else {
      dispatch({
        type: OTCTradeActionType.UPDATE_OTC_TRADE,
        payload: amounts,
      });
    }
  };

  const handleSubmit = async (otcTrade: AfriexOTCTrade) => {
    setIsLoading(true);
    try {
      if (!otcTrade.fromSymbol) {
        showErrorMessage(`Please select the origin currency`);
        return;
      }
      if (!otcTrade.toSymbol) {
        showErrorMessage(`Please select the destination currency`);
        return;
      }
      if (!otcTrade.fromAmount) {
        showErrorMessage(`Please enter the amount sent`);
        return;
      }
      if (!otcTrade.toAmount) {
        showErrorMessage(`Please enter the amount received`);
        return;
      }

      if (otcTrade.id) {
        const fieldsToOmit = [
          "submittedBy",
          "approvedBy",
          "createdAt",
          "updatedAt",
          "availableAmount",
        ];
        const endTrade = omit(otcTrade, fieldsToOmit) as AfriexOTCTrade;

        await ratesService.updateOTCTrade(endTrade);
        showSuccessMessage(`OTC Trade updated successfully`);
      } else {
        await ratesService.createOTCTrade({
          otcRates: newOTCRates,
          otcVolume: otcTrade,
        });
        showSuccessMessage(`OTC Trade created successfully`);
      }

      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      showErrorMessage(`Error saving trade ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    otcTrade,
    newTradeRate,
    dispatch,
    isLoading,
    updateTradeValues,
    handleSubmit,
    setNewTradeRate,
  };
};

export default useOTCTradeUpdate;

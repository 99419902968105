import { Button, Link } from "../../../components";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { formatValues } from "../../../utils/dashboard";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexReferralStatus,
} from "../../../types";
import { ReferralRowType } from "../../../types/AfriexReferral";

const ReferralSummary = ({
  item,
  handlePayment,
  handleBlockPayout,
}: ReferralRowType) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{item.toName}</h2>
        <Link
          to={`/users/${item.fromUserId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-cyan-700 mr-2 hover:underline"
        >
          View referrer details
        </Link>
        <Link
          to={`/users/${item.toUserId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-cyan-700 mr-2 hover:underline"
        >
          View referree details
        </Link>
      </div>

      <div>
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referral Code</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.fromUserName}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referree</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.toName}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referree Username</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.toUserName}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Transaction Amount So Far</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatValues(
              Number(item.cumulativeTransactionAmount ?? 0),
              "money"
            )}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Is From Afriex Staff</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.isFromStaff?.toString()}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referral Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.status?.toLocaleUpperCase()}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Is Payout Blocked</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.isPayoutBlocked?.toString() ?? "false"}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-end gap-6 mt-4">
        <PermissionsProvider
          permission={AfriexPermissions.REFERRAL_PAGE}
          action={AfriexActions.CLICK_REFERRAL_PAYOUT}
        >
          <Button
            disabled={item?.status !== AfriexReferralStatus.Qualified}
            data-tip
            data-for="payoutButton"
            onClick={() => {
              handlePayment((item as any)._id);
              return false;
            }}
            variant={"solid"}
            colorScheme="cyan"
          >
            Pay
          </Button>

          <Button
            disabled={item?.status !== AfriexReferralStatus.Qualified}
            data-tip
            data-for="blockPayout"
            onClick={() => {
              handleBlockPayout((item as any)._id);
              return false;
            }}
            variant={"solid"}
            colorScheme="red"
          >
            {item?.isPayoutBlocked ? "Unblock Payout" : "Block Payout"}
          </Button>
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default ReferralSummary;

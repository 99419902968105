import { SVGProps } from "react";
import { appPaths } from "./routesConfig";
import { PageRoles } from "./pageRoles";
import { AfriexTransactionStatus, AfriexVerificationStatus } from "../types";
import {
  HomeIcon,
  UsersIcon,
  GiftIcon,
  FingerPrintIcon,
  LibraryIcon,
  ChartSquareBarIcon,
  BanIcon,
  SparklesIcon,
  ClockIcon,
  XCircleIcon,
  ViewListIcon,
  CollectionIcon,
  AdjustmentsIcon,
  PresentationChartBarIcon,
  PuzzleIcon,
  RefreshIcon,
  GlobeIcon,
  CurrencyDollarIcon,
  SpeakerphoneIcon,
  TicketIcon,
  CheckCircleIcon,
  IdentificationIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/outline";

export interface ISubMenuConfig {
  label: string;
  path: string;
  roles: string[];
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export interface IMenuConfig extends ISubMenuConfig {
  subMenu: ISubMenuConfig[];
}

export const menuConfig: IMenuConfig[] = [
  {
    label: "Home",
    path: appPaths.home,
    icon: HomeIcon,
    subMenu: [],
    roles: PageRoles.ALL_ROLES,
  },
  {
    label: "Transactions",
    path: appPaths.transactions,
    icon: ChartSquareBarIcon,
    roles: PageRoles.TRANSACTIONS_PAGE,
    subMenu: [
      {
        label: "Pending",
        icon: ClockIcon,
        path: `${appPaths.transactions}?status=${AfriexTransactionStatus.PENDING}`,
        roles: PageRoles.TRANSACTIONS_PAGE,
      },
      {
        label: "Failed",
        icon: XCircleIcon,
        path: `${appPaths.transactions}?status=${AfriexTransactionStatus.FAILED}`,
        roles: PageRoles.TRANSACTIONS_PAGE,
      },
    ],
  },
  {
    label: "Users",
    path: appPaths.users,
    icon: UsersIcon,
    subMenu: [],
    roles: PageRoles.USERS_PAGE,
  },
  {
    label: "KYC",
    path: appPaths.kyc,
    icon: FingerPrintIcon,
    subMenu: [
      {
        label: "Pending",
        icon: ClockIcon,
        path: `${appPaths.kyc}/?status=${AfriexVerificationStatus.Pending}`,
        roles: PageRoles.KYC_PAGE,
      },
      {
        label: "Failed",
        icon: XCircleIcon,
        path: `${appPaths.kyc}/?status=${AfriexVerificationStatus.Failed}`,
        roles: PageRoles.KYC_PAGE,
      },
      {
        label: "Verified",
        icon: CheckCircleIcon,
        path: `${appPaths.kyc}/?status=${AfriexVerificationStatus.Success}`,
        roles: PageRoles.KYC_PAGE,
      },
      {
        label: "Reuploaded",
        icon: CheckCircleIcon,
        path: `${appPaths.kyc}/?status=${AfriexVerificationStatus.Reuploaded}`,
        roles: PageRoles.KYC_PAGE,
      },
      {
        label: "Requested Docs",
        icon: CheckCircleIcon,
        path: `${appPaths.kyc}/?status=${AfriexVerificationStatus.ReuploadRequested}`,
        roles: PageRoles.KYC_PAGE,
      },
    ],
    roles: PageRoles.KYC_PAGE,
  },
  {
    label: "Referrals",
    path: appPaths.referral,
    icon: SparklesIcon,
    roles: PageRoles.REFERRAL_PAGE,
    subMenu: [
      {
        label: "List",
        icon: ViewListIcon,
        path: `${appPaths.referral}/list`,
        roles: PageRoles.REFERRAL_PAGE,
      },
      {
        label: "Tiers",
        icon: CollectionIcon,
        path: `${appPaths.referral}/tiers`,
        roles: PageRoles.REFERRAL_PAGE,
      },
    ],
  },
  {
    label: "Notifications",
    path: appPaths.notification,
    icon: SpeakerphoneIcon,
    subMenu: [],
    roles: PageRoles.NOTIFICATIONS_PAGE,
  },
  {
    label: "Disputes",
    path: appPaths.disputes,
    icon: ReceiptRefundIcon,
    subMenu: [],
    roles: PageRoles.DISPUTES_PAGE,
  },
  {
    label: "Rates",
    path: appPaths.rates,
    icon: RefreshIcon,
    subMenu: [],
    roles: PageRoles.RATES_PAGE,
  },
  {
    label: "Fees",
    path: appPaths.fees,
    icon: CurrencyDollarIcon,
    subMenu: [],
    roles: PageRoles.FEES_PAGE,
  },
  {
    label: "Bulk Payments",
    path: appPaths.bulkPayments,
    icon: TicketIcon,
    subMenu: [],
    roles: PageRoles.BULK_PAYMENTS_PAGE,
  },
  {
    label: "Processors",
    icon: LibraryIcon,
    path: appPaths.processors,
    subMenu: [
      {
        label: "List",
        icon: ViewListIcon,
        path: `${appPaths.processors}`,
        roles: PageRoles.PROCESSORS_PAGE,
      },
      {
        label: "Metrics",
        icon: PresentationChartBarIcon,
        path: `${appPaths.processors}/metrics`,
        roles: PageRoles.PROCESSORS_PAGE,
      },
    ],
    roles: PageRoles.PROCESSORS_PAGE,
  },
  {
    label: "Blocked",
    icon: BanIcon,
    path: appPaths.blockedPaymentMethods,
    subMenu: [
      {
        label: "Methods",
        icon: PuzzleIcon,
        path: appPaths.blockedPaymentMethods,
        roles: PageRoles.BLOCKED_PAGE,
      },
    ],
    roles: PageRoles.BLOCKED_PAGE,
  },
  {
    label: "Rewards",
    path: appPaths.rewards,
    icon: GiftIcon,
    subMenu: [
      {
        label: "List",
        icon: ChartSquareBarIcon,
        path: appPaths.cards,
        roles: PageRoles.REWARDS_PAGE,
      },
    ],
    roles: PageRoles.REWARDS_PAGE,
  },
  {
    label: "Settings",
    icon: AdjustmentsIcon,
    path: appPaths.settings,
    subMenu: [
      {
        label: "Team",
        icon: IdentificationIcon,
        path: appPaths.admins,
        roles: PageRoles.SETTINGS_PAGE,
      },
      {
        label: "Countries & Currencies",
        icon: GlobeIcon,
        path: appPaths.config,
        roles: PageRoles.SETTINGS_PAGE,
      },
    ],
    roles: PageRoles.SETTINGS_PAGE,
  },
];

export interface DashCardConfig {
  label: string;
  valueType: "money" | "rate";
}

export const dashCardConfig: DashCardConfig[] = [
  {
    label: "Transaction Count",
    valueType: "rate",
  },
  {
    label: "Debits",
    valueType: "rate",
  },
  {
    label: "Debit Value",
    valueType: "money",
  },
  {
    label: "Credits",
    valueType: "rate",
  },
  {
    label: "Credit Value",
    valueType: "money",
  },
];

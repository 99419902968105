import { useState } from "react";
import { Link } from "react-router-dom";
import { AfriexRewardCard } from "../../../types";
import { formatDate } from "../../../utils/date";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { Button } from "../../../components";
import { appPaths } from "../../../config/routesConfig";

const RewardCardTable = ({ items }: { items: AfriexRewardCard[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md table-fixed`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="w-12 px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>
          <th
            scope="col"
            className="w-1/5 px-3 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Code
          </th>
          <th
            scope="col"
            className="w-2/5 px-3 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Is Used
          </th>
          <th
            scope="col"
            className="w-1/6 px-3 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Used By
          </th>
          <th
            scope="col"
            className="w-1/6 px-3 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date
          </th>
          <th
            scope="col"
            className="w-12 px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible invisible"
          >
            Link
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <TRow item={item} key={index} />;
        })}
      </tbody>
    </table>
  );
};

const TRow = ({ item }: { item: AfriexRewardCard }) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
      <td className="w-12 pr-2 py-4 whitespace-nowrap text-right">
        <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
          <div
            className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
              isExpanded && "bg-cyan-50"
            }`}
          >
            {isExpanded ? (
              <MinusIcon className="w-4 h-4 text-gray-700" />
            ) : (
              <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
            )}
          </div>
        </Button>
      </td>

      <td
        className={`flex-1 px-3 py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
      >
        *****
      </td>

      <td className={`flex-1 px-3 py-4 text-left ${textClassName}`}>
        <div className="break-words">
          {item.isDeactivated?.toString()?.toLocaleUpperCase()}
        </div>
      </td>

      <td
        className={`w-5/6 px-3 py-4 whitespace-nowrap text-left ${textClassName}`}
      >
        {item.isDeactivated ? (
          <Link to={`/${appPaths.users}/${item?.redeemedBy?.userId}`}>
            {item?.redeemedBy?.name}
          </Link>
        ) : (
          "-"
        )}
      </td>

      <td
        className={`flex-1 px-3 py-4 whitespace-nowrap text-left ${textClassName}`}
      >
        {item?.redeemedBy?.redeemedAt?.toString() ??
          formatDate(item?.updatedAt?.toString() ?? "")}
      </td>

      <td className="w-12 px-3 py-4 whitespace-nowrap text-left ${textClassName}">
        <div
          className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ChevronRightIcon className=" w-5 h-5" />
        </div>
      </td>
    </tr>
  );
};

export default RewardCardTable;

import { TransactionTypes } from "../../../types/Transaction";

import { ReactNode } from "react";
import { AfriexTransaction } from "../../../types/AfriexTransaction";
import { AdminTransactionTypes } from "../../../types/Transaction";
import { formatValues } from "../../../utils/dashboard";
import { SupportedAssets } from "../../../types/AssetTypes";

const formatStatementValues = (
  transaction: AfriexTransaction
): {
  description: string;
  credit: ReactNode;
  debit: ReactNode;
  balance: ReactNode;
} => {
  const typeCheck =
    Object.values(AdminTransactionTypes).find(
      (key) =>
        key ===
        AdminTransactionTypes[
          transaction.type as unknown as keyof typeof AdminTransactionTypes
        ]
    ) || transaction.type;

  const description =
    transaction.type === TransactionTypes.TRANSFER
      ? transaction.processorResponseMeta?.description
      : transaction.type;
  const destinationBalanceAfter =
    transaction?.meta?.balanceAfter?.[0]?.balances[
      transaction.destinationAsset as SupportedAssets
    ];

  const sourceBalanceAfter =
    transaction?.meta?.balanceAfter?.[0]?.balances[
      transaction.sourceAsset as SupportedAssets
    ];

  const asset = destinationBalanceAfter
    ? transaction?.destinationAsset
    : transaction?.sourceAsset;

  const amount = destinationBalanceAfter
    ? Number(transaction?.destinationAmount)
    : Number(transaction?.sourceAmount);

  const getAmountValue = (value: number) => formatValues(value, "money");

  switch (typeCheck) {
    case TransactionTypes.DEPOSIT:
    case TransactionTypes.REFERRAL:
    case TransactionTypes.WELCOME_BONUS:
      return {
        description: description,
        credit: (
          <>
            {getAmountValue(amount)} {asset}
          </>
        ),
        debit: "",
        balance: (
          <>
            {getAmountValue(Number(sourceBalanceAfter))} {asset}
          </>
        ),
      };
    case TransactionTypes.WITHDRAW:
    case TransactionTypes.TRANSFER:
      return {
        description: description,
        credit: "",
        debit: (
          <>
            {getAmountValue(amount)} {asset}
          </>
        ),
        balance: (
          <>
            {getAmountValue(
              Number(destinationBalanceAfter || sourceBalanceAfter)
            )}{" "}
            {asset}
          </>
        ),
      };
    case TransactionTypes.SWAP:
      return {
        description: description,
        credit: (
          <>
            {getAmountValue(Number(transaction.destinationAmount))}{" "}
            {transaction.destinationAsset}
          </>
        ),
        debit: (
          <>
            {getAmountValue(Number(transaction?.sourceAmount))}{" "}
            {transaction?.sourceAsset}
          </>
        ),
        balance: (
          <>
            {getAmountValue(Number(sourceBalanceAfter))}{" "}
            {transaction.sourceAsset}
          </>
        ),
      };
    case TransactionTypes.REVERSAL:
      return {
        description: description,
        credit: (
          <>
            {getAmountValue(amount)} {asset}
          </>
        ),
        debit: "",
        balance: (
          <>
            {getAmountValue(
              Number(destinationBalanceAfter || sourceBalanceAfter)
            )}{" "}
            {asset}
          </>
        ),
      };
    default:
      return {
        description: description,
        credit: (
          <>
            {getAmountValue(amount)} {asset}
          </>
        ),
        debit: "",
        balance: (
          <>
            {getAmountValue(Number(sourceBalanceAfter))} {asset}
          </>
        ),
      };
  }
};

export default formatStatementValues;

import { Link } from "react-router-dom";
import * as formatDate from "../../../utils/date";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { Button } from "../../../components";
import React, { useState } from "react";
import { AfriexPerson } from "../../../types";
import countries from "../../../constants/countries";
import { camelToTitle } from "../../../utils/camelToTitle";
import KycSummary from "./Summary";
import { capitalizeWords } from "../../../utils/dashboard";

type ExtraRowArg = AfriexPerson;

const KycTable = ({
  items,
  extraRow,
}: {
  items: AfriexPerson[];
  extraRow?: (user?: ExtraRowArg) => React.ReactNode;
}) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Country
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <KycTableRow item={item} key={index} extraRow={extraRow} />;
        })}
      </tbody>
    </table>
  );
};

const KycTableRow = ({
  item,
  extraRow,
}: {
  item: AfriexPerson;
  extraRow?: (user?: AfriexPerson) => React.ReactNode;
}) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const createdAt = new Date(item?.createdAt as string);
  return (
    <React.Fragment>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td className="pr-6 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item.name?.fullName}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {capitalizeWords(camelToTitle(item.kyc?.status ?? ""))}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.currentCountry} {countries[item.currentCountry]}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(createdAt?.toISOString() ?? "")}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Link
            to={`/kyc/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500">
              <ChevronRightIcon className=" w-5 h-5" />
            </div>
          </Link>
        </td>
      </tr>

      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <KycSummary
                user={item}
                extraRow={extraRow ? extraRow(item) : null}
              />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default KycTable;

import { useState } from "react";
import { AfriexDispute, AfriexDisputeStatus } from "../../types/AfriexDispute";
import { Badge, Button } from "../../components";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { statusColors } from "../../types/Transaction";
import * as formatDate from "../../utils/date";
import DisputeSummary from "./DisputeSummary";
import { formatValues } from "../../utils/dashboard";

export interface DisputeTableProps {
  items: AfriexDispute[];
  handleUpdateDisputeModal: (disputeId: string) => void;
}

const DisputeTable = ({
  items,
  handleUpdateDisputeModal,
}: DisputeTableProps) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Icon
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Amount
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Currency
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider w-52"
          >
            Reason
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider w-48"
          >
            Comments
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider w-48"
          >
            Created
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Link
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return (
            <TRow
              item={item}
              key={index}
              handleUpdateDisputeModal={handleUpdateDisputeModal}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TRow = ({
  item,
  handleUpdateDisputeModal,
}: {
  item: AfriexDispute;
  handleUpdateDisputeModal: DisputeTableProps["handleUpdateDisputeModal"];
}) => {
  const textClassName = "text-sm text-gray-800";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const disputeStatus = [
    AfriexDisputeStatus.CLOSED,
    AfriexDisputeStatus.FRAUD,
  ].includes(item.status as AfriexDisputeStatus)
    ? "failed"
    : item.status === AfriexDisputeStatus.LEGIT
      ? "successful"
      : item.status;

  return (
    <>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td className="pr-4 py-4 whitespace-nowrap text-right">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
                isExpanded && "bg-cyan-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-gray-700" />
              ) : (
                <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
              )}
            </div>
          </Button>
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {formatValues(item.amount, "money")}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.currency}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <Badge
            label={item.status}
            colorScheme={statusColors[disputeStatus]}
          />
        </td>

        <td
          className={`pr-3 py-4 whitespace-nowrap text-left ${textClassName}`}
        >
          <p
            className="truncate overflow-hidden whitespace-nowrap w-52"
            title={item.reason}
          >
            {item.reason}
          </p>
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <p
            className="truncate overflow-hidden whitespace-nowrap w-48"
            title={item.userNote}
          >
            {item.userNote ?? "-"}
          </p>
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(String(item.createdAt || item.updatedAt), true)}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          <div className="w-5 h-5 flex justify-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500">
            <ChevronRightIcon className=" w-5 h-5" />
          </div>
        </td>
      </tr>
      {isExpanded && (
        <tr className="pr-5">
          <td className="p-0 m-0 whitespace-nowrap" colSpan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-50 w-full">
              <DisputeSummary
                data={item}
                handleUpdateDisputeModal={handleUpdateDisputeModal}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default DisputeTable;

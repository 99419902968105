import { useCallback, useEffect, useState } from "react";
import { ratesService } from "../../../services/ratesService";
import { AfriexOTCTrade, AfriexQueryParams } from "../../../types";
import { OTCTradeListProps } from "./types";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import useFilters from "../../../hooks/useFilters";

const useOTCTradeList = (): OTCTradeListProps => {
  const [otcTradeList, setOtcList] = useState<AfriexOTCTrade[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);
  const [ratesTotal, setRatesTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexQueryParams>({});
  const [filtersToApply, setFiltersToApply] = useState<AfriexQueryParams>({});

  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllOTCTrades = useCallback(
    async (params: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: items, total } = await ratesService.getOTCTradeList({
          ...params,
          ...currentFilters,
        });
        setOtcList(items);
        setRatesTotal(total ?? 0);
        if (total && total > 10) {
          setShowPagination(true);
        }
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  useEffect(() => {
    fetchAllOTCTrades({});
  }, [fetchAllOTCTrades]);

  const handleApplyFilters = useCallback(() => {
    applyFilters(filtersToApply);
    setIsPageReset((prev) => !prev);
  }, [applyFilters, filtersToApply]);

  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  const selectAppliedFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        setFilterCount(Object.keys(updatedFilters).length);
        return updatedFilters;
      });
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({});
    setFiltersToApply({});
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
  };

  const handleApprove = useCallback(async (id: string) => {
    try {
      await ratesService.approveOTCTrade(id);
      showSuccessMessage(`OTC rate approved successfully`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e: any) {
      showErrorMessage(`Error approving OTC rate: ${e?.message}`);
      setError(e as any);
    }
  }, []);

  return {
    error,
    fetchAllOTCTrades,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: ratesTotal,
    },
    showPagination,
    otcTradeList,
    filterCount,
    isPageReset,
    currentFilters,
    filtersToApply,
    clearFilters: cleanFilters,
    selectAppliedFilters,
    handleApplyFilters,
    selectCurrentFilters,
    handleApprove,
  };
};

export default useOTCTradeList;

import {
  AmountInput,
  Button,
  CustomInput,
  CustomModal,
  DatePicker,
  SpinningLoader,
} from "../../../components";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import { useEffect, useState } from "react";
import useOTCTradeUpdate from "./useOTCTradeUpdate";
import { AfriexAmount, AfriexOTCTrade } from "../../../types";

interface OTCTradeModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  title: string;
  initialData?: AfriexOTCTrade;
}

const OTCTradeModal = ({
  isOpen,
  onClose,
  initialData,
  title,
}: OTCTradeModalProps) => {
  const {
    otcTrade,
    handleSubmit,
    isLoading,
    newTradeRate,
    setNewTradeRate,
    updateTradeValues,
  } = useOTCTradeUpdate(onClose, initialData);
  const { timestamp, fromSymbol, toSymbol, fromAmount, toAmount } = otcTrade;
  const [date, setDate] = useState(timestamp || new Date());

  const isSubmitDisabled = () => {
    return (
      !fromAmount ||
      !toAmount ||
      !newTradeRate ||
      isLoading ||
      !fromSymbol ||
      !toSymbol
    );
  };

  useEffect(() => {
    if (otcTrade.rate) {
      setNewTradeRate(Number(otcTrade.rate));
    }
  }, [otcTrade.rate, setNewTradeRate]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-5/6 md:w-4/6 lg:w-6/12 xl:w-5/12 2xl:w-4/12"
      closeOnOutsideClick
      title={title}
      allowOverflow
    >
      <div className="px-4 pt-3 h-full">
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(otcTrade);
            }}
            className="flex flex-col space-y-5 justify-between h-full"
          >
            <div className="flex flex-col space-y-5 md:space-y-10">
              <div className="flex flex-col sm:flex-row gap-3">
                <DatePicker
                  label="Date & Time of Transaction"
                  selectedDate={
                    timestamp ? new Date(timestamp as unknown as string) : date
                  }
                  setSelectedDate={(date: Date) => {
                    setDate(date);
                    updateTradeValues(date, "date");
                  }}
                  placeholderText="Select Date"
                  showTime
                  containerStyle="sm:w-3/4"
                />

                <CustomInput
                  label="Rate"
                  type="number"
                  disabled
                  value={newTradeRate.toString()}
                  className="w-full"
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <AmountInput
                  label="Amount Traded"
                  onChange={(value: AfriexAmount) =>
                    updateTradeValues(value, "fromAmount")
                  }
                  value={{ amount: fromAmount, currency: fromSymbol }}
                  containerStyle="sm:w-1/2"
                />

                <AmountInput
                  label="Amount Received"
                  onChange={(value: AfriexAmount) =>
                    updateTradeValues(value, "toAmount")
                  }
                  value={{ amount: toAmount, currency: toSymbol }}
                  containerStyle="sm:w-1/2"
                />
              </div>
            </div>

            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline" colorScheme="cyan">
                Cancel
              </Button>

              <Button
                disabled={isSubmitDisabled()}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                Submit Trade
              </Button>
            </div>
          </form>
        )}
      </div>
    </CustomModal>
  );
};

export default OTCTradeModal;

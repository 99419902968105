import { AfriexOTCTrade, AfriexQueryParams } from "../../../types";

export interface OTCTradeListProps {
  error: typeof Error | null;
  isLoading: boolean;
  isSearch: boolean;
  isPageReset: boolean;
  pageInfo: {
    total: number;
    page: number;
  };
  showPagination: boolean;
  otcTradeList: AfriexOTCTrade[];
  fetchAllOTCTrades: (params: AfriexQueryParams) => void;
  setPage: (page: number) => void;
  filterCount: number;
  currentFilters: AfriexQueryParams;
  filtersToApply: AfriexQueryParams;
  clearFilters: () => void;
  selectAppliedFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  selectCurrentFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  handleApplyFilters: () => void;
  handleAutoComplete?: (search: string) => void;
  handleApprove: (id: string) => Promise<void>;
}

export enum OTCTradeActionType {
  UPDATE_OTC_TRADE = "UPDATE_OTC_TRADE",
}

interface UpdateOTCTradeAction {
  type: OTCTradeActionType.UPDATE_OTC_TRADE;
  payload: Partial<AfriexOTCTrade>;
}

export type OTCTradeAction = UpdateOTCTradeAction;

export type OTCFiltersProps = {
  filterCount: number;
  currentFilters: AfriexQueryParams;
  filtersToApply: AfriexQueryParams;
  clearFilters: () => void;
  selectAppliedFilters: (name: string, date: string | Date | undefined) => void;
  handleApplyFilters: () => void;
  selectCurrentFilters: (
    name: string,
    value: string | Date | undefined
  ) => void;
  handleAutoComplete?: (search: string) => void;
};

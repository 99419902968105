import {
  AfriexOTCRate,
  AfriexOTCTrade,
  AfriexPaginatedResponse,
  AfriexQueryParams,
  AfriexRate,
  AfriexRates,
  SupportedCurrencies,
} from "../types";
import axios from "../api/axios";

export const ratesService = {
  getAppRatesList: async (): Promise<AfriexRate[]> => {
    const url = `/v2/admin/rates`;
    const response = await axios.get(url);

    return response?.data;
  },
  getOTCRatesList: async (): Promise<AfriexOTCRate[]> => {
    const url = `/v2/admin/rates`;
    const response = await axios.get(url);
    return response?.data;
  },

  updateRates: async (payload: any): Promise<AfriexRates> => {
    const url = `/v2/admin/rates`;
    const response = await axios.post(url, payload);
    return response?.data;
  },
  // This service is currently not in use as the arbitrage detection is done on the FE using web workers

  getSymbolList: async (): Promise<SupportedCurrencies[]> => {
    const url = `/v2/rates/symbols`;
    const response = await axios.get(url);
    return response?.data?.symbols;
  },
  getPromoList: async (): Promise<SupportedCurrencies[]> => {
    const url = `/v2/rates/symbols`;
    const response = await axios.get(url);
    return response?.data?.symbols;
  },
  getOTCTradeList: async (
    filters: AfriexQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexOTCTrade[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as any;
    const params = { ...paginationParams, ...filters };
    const url = `/v2/admin/otc-volume?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  createOTCTrade: async (payload: {
    otcVolume: AfriexOTCTrade;
    otcRates: AfriexOTCRate[];
  }): Promise<AfriexOTCTrade> => {
    const url = `/v2/admin/otc-volume`;
    const response = await axios.post(url, payload);
    return response?.data;
  },
  updateOTCTrade: async (trade: AfriexOTCTrade): Promise<AfriexOTCTrade> => {
    const url = `/v2/admin/otc-volume`;
    const response = await axios.patch(url, trade);
    return response?.data;
  },
  approveOTCTrade: async (rateId: string): Promise<AfriexOTCTrade> => {
    const url = `/v2/admin/otc-volume/${rateId}`;
    const response = await axios.patch(url, {});
    return response?.data;
  },
};

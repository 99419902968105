import * as formatDate from "../../../utils/date";
import { capitalizeWords } from "../../../utils/dashboard";
import { AfriexTransaction } from "../../../types/AfriexTransaction";
import formatStatementValues from "./formatStatementValues";

const StatementTable = ({
  transactions,
}: {
  transactions: AfriexTransaction[];
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 border shadow-sm rounded-md">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-2 pl-4 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
          >
            Date
          </th>
          <th
            scope="col"
            className="py-2 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
          >
            Description
          </th>
          <th
            scope="col"
            className="py-2 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
          >
            Credit
          </th>
          <th
            scope="col"
            className="py-2 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
          >
            Debit
          </th>
          <th
            scope="col"
            className="py-2 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
          >
            Balance
          </th>
        </tr>
      </thead>

      <tbody>
        {transactions.map((item, idx) => {
          return <TRow transaction={item} key={idx} />;
        })}
      </tbody>
    </table>
  );
};

export default StatementTable;

const TRow = ({ transaction }: { transaction: AfriexTransaction }) => {
  const { description, credit, debit, balance } =
    formatStatementValues(transaction);
  return (
    <>
      <tr className="border-b">
        <td
          className={`pl-4 py-4 whitespace-nowrap text-left text-xs text-gray-800 font-medium`}
        >
          {formatDate.DDMonYYYY(transaction.createdAt?.toString() ?? "")}
        </td>
        <td
          className={`py-4 whitespace-nowrap text-left text-xs text-gray-800 font-medium`}
        >
          {capitalizeWords(description?.replace(/_/g, " "))}
        </td>
        <td
          className={`py-4 whitespace-nowrap text-left text-xs text-gray-800 font-medium`}
        >
          {credit}
        </td>
        <td
          className={`py-4 whitespace-nowrap text-left text-xs text-gray-800 font-medium`}
        >
          {debit}
        </td>
        <td
          className={`py-4 whitespace-nowrap text-left text-xs text-gray-800 font-medium`}
        >
          {balance}
        </td>
      </tr>
    </>
  );
};

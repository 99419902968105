import { Pagination, TableSkeleton } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";

import OTCTradesFilters from "./OTCTradesFilters";
import OTCTradeTable from "./OTCTradeTable";
import useOTCTradeList from "./useOTCTradeList";

const OTCTrades = () => {
  const {
    otcTradeList,
    pageInfo,
    isSearch,
    isPageReset,
    fetchAllOTCTrades,
    filterCount,
    filtersToApply,
    currentFilters,
    clearFilters,
    selectCurrentFilters,
    selectAppliedFilters,
    handleApplyFilters,
    handleApprove,
    isLoading,
  } = useOTCTradeList();

  return (
    <main className="my-5 mx-4 md:mx-10">
      <OTCTradesFilters
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <OTCTradeTable items={otcTradeList} handleApprove={handleApprove} />
        )}
      </section>

      {otcTradeList && otcTradeList.length ? (
        <Pagination
          onPageChange={fetchAllOTCTrades as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default OTCTrades;
